import Vue from "vue";
import Vuex from "vuex";
import navMenu from "./modules/navMenu.js";
import permission from "./modules/permission.js";
import { dhtUserInfoApi } from "@/api/user.js";
import router, { resetRouter } from "@/router/index.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    navMenu,
    permission
  },
  state: {
    token: '',
    userData: null,
    routes: null,
    breadcrumbList: [],
    routeUrls: {},
    bjqd_list: []

  },
  actions: {
    async getUserInfo(context) {
      let res = await dhtUserInfoApi();
      if (!res) return false;
      window.localStorage.setItem('luruuser', JSON.stringify(res.data.user))
      context.commit('setUserData', res.data.user || null);
      context.state.bjqd_list = res.data.bjqd_list
    }
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload;
      localStorage.setItem('dht_auth_token', payload);
    },
    setUserData(state, payload) {
      state.userData = payload;
      // localStorage.setItem('dht_user_data', JSON.stringify(payload));
    },
    setRoutes(state, payload) {
      state.routes = payload;
    },
    setRouteUrls(state, payload) {
      state.routeUrls = payload || [];
    },
    setBreadcrumbList(state, payload) {
      state.breadcrumbList = [{ path: '/', title: '首页' }];
      state.routes && state.routes.forEach((d, i) => {
        if (d.name === payload.meta.pname) {
          state.breadcrumbList.push({
            path: d.path,
            title: d.meta.title
          });
        }
      });
      state.breadcrumbList.push({
        path: payload.path,
        title: payload.meta.title
      });
    },
    logout(state, payload) {
      state.token = '';
      state.userData = null;
      state.routes = null;
      state.navMenu.list = [];
      state.navMenu.activeIndex = {};
      localStorage.removeItem('dht_auth_token');
      // localStorage.removeItem('dht_user_data');
      resetRouter();
      router.push('/login');
    }
  },
  getters: {
    token(state) {
      if (!state.token) {
        state.token = localStorage.getItem('dht_auth_token') || '';
      }
      return state.token;
      // return localStorage.getItem('dht_auth_token') || '';
    },
    userData(state) {
      // if (!state.userData) {
      //   state.userData = JSON.parse(localStorage.getItem('dht_user_data'));
      // }
      return state.userData || {};
    },
    routes(state) {
      return state.routes || [];
    }
  }
});

export default store;